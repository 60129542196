import React from "react"

class AboutPage extends React.Component {
  render() {
    return (
      <>
        <h2>About</h2>
        <p>This app uses React.</p>
        <p>It was created to show the courses I have working on</p>
        <p>For more info please visit: <a href='https://jaymgonzalez.com'>my personal website</a></p>
      </>
    )
  }
}

export default AboutPage
